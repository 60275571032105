<template>
  <v-container
    class="fill-height d-flex align-center px-3 mx-auto"
    style="max-width: 800px;"
    >
    <div
      class="background"
      ></div>

    <div
      class="font-weight-bold text-h1 white--text"
      style="position: absolute; right: 12px; bottom: 12px"
      >
      Tiantar
    </div>
    <v-card
      rounded="lg"
      class="grow"
      >
      <v-card-title
        class="py-2 primary white--text lower-opacity"
        >
        Iniciar sesión
      </v-card-title>

      <v-card-text
        class="pt-3 lower-background-opacity"
        >
        <v-form
          v-model="valid"
          lazy-validation
          ref="form"
          >
          <v-alert
            class="fill-width"
            outlined
            v-model="alert.alert"
            type="error"
            transition="scale-transition">
          </v-alert>
          <v-text-field
            label="Email"
            ref="email"
            solo
            flat
            outlined
            class="rounded-lg"
            v-model="login.email"
            type="text"
            :rules="[ v => !!v || 'El campo es obligatorio']">
          </v-text-field>

          <v-text-field
            id="password"
            label="Contraseña"
            ref="password"
            solo
            flat
            outlined
            class="rounded-lg"
            v-model="login.password"
            :type="show_password ? 'text' : 'password'"
            :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="show_password = !show_password"
            @keyup.enter="auth"
            :rules="[ v => !!v || 'El campo es obligatorio']">
          </v-text-field>
        </v-form>

        <div
          class="text-center"
          >
          <v-btn
            text
            small
            :to="{ name: 'forgot_password' }"
            color="primary"
            >
            ¿Olvidaste tu contraseña?
          </v-btn>
        </div>
      </v-card-text>

      <v-card-actions
        class="px-3 lower-background-opacity"
        >
        <v-btn
          @click.stop="auth"
          :disabled="loading"
          :loading="loading"
          tile
          large
          width="100%"
          class="rounded-lg justify-center align-center flex-column"
          color="primary">
          Iniciar sesión
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { SignIn } from '@/graphql/mutations/users'

export default {
  name: 'Login',

  data: () => ({
    valid: true,
    loading: false,
    errorMessages: "",
    show_password: false,
    alert: {
      alert: false,
      message: null
    },
    login: {
      email: null,
      password: null
    },
  }),

  methods: {
    auth () {
      if (this.valid) this.valid = this.$refs.form.validate()

      if (this.valid) {
        this.loading = true;
        this.$apollo.mutate({
          mutation: SignIn,
          variables: {
            input: {
              credentials: this.login
            }
          }
        }).then(response => {
          this.$store.commit("login");
          localStorage.setItem("auth_token", response.data.signIn.token);
          this.$store.commit("setCurrentUser", response.data.signIn.user);
          this.$router.push(this.$route.query.redirect || '/')
        }).catch(error => {
          this.loading = false;
          this.alert.message = `${error.graphQLErrors[0].extensions.field}_${error.graphQLErrors[0].extensions.message}`
          this.alert.alert = true
        })
      }

    },
  }
}
</script>
